import * as React from "react";
function AboutCard(props) {
  var chips = [];
  for (var i = 0; i < props.tags.length; i++) {
    chips.push(<div className="chip starblue ">{props.tags[i]}</div>);
  }

  var buttonTitle = props.customMessage;
  if (!buttonTitle) {
    buttonTitle = "View";
  }

  return (
    <>
      <div className="aboutcard-background">
        <div className="row">
          <div className="pad-image">
            <div className="aboutcard-image-wrapper">
              <img
                className="aboutcard-image"
                src={process.env.PUBLIC_URL + props.image}
                alt={props.title}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <h3 className="aboutcard-title"> {props.title}</h3>
          <div className="aboutcard-chips">{chips}</div>
        </div>
        <div className="row">
          <p className="aboutcard-description">{props.description}</p>
          <div className="row">
            <div className="aboutcard-button-wrapper">
              <a
                className="btn aboutcard-button col s12"
                href={props.link}
                target="_blank"
                rel="noreferrer"
              >
                {buttonTitle}
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutCard;
