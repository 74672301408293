import * as React from "react";
import "./../../css/App.css";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
//Create Navbar at the top of page

class Navbar extends React.Component {
  render() {
    return (
      <>
        <nav className="transparent nav-custom">
          <div className="nav-wrapper light-blut-text text-darken-4 nav-custom">
            <Link to={"/"} className="brand-logo light-blue-text text-darken-4">
              PROJECTSTAR
            </Link>
            <Sidebar />
            <ul
              id="nav-mobile"
              className="right hide-on-med-and-down light-blue-text darken-4"
            >
              <li>
                <Link
                  to={"/"}
                  className=" light-blue-text text-darken-4 nav-text"
                >
                  Home
                </Link>
              </li>

              <li>
                <Link
                  to={"/about"}
                  className="light-blue-text text-darken-4 nav-text"
                >
                  About
                </Link>
              </li>

              <li>
                <Link
                  to={"/content"}
                  className="light-blue-text text-darken-4 nav-text"
                >
                  Content
                </Link>
              </li>

              <li>
                <Link
                  to={"/contact"}
                  className="light-blue-text text-darken-4 nav-text"
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </>
    );
  }
}

export default Navbar;
