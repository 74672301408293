import * as React from "react";
import "./../../css/About.css";

/*
 * Header detail at the top of each page
 * Props Used in this Component:
 *  - content: The Title of the page
 */

function Header(props) {
  return (
    <>
      <div className="col s12 m4 l4 header-rect-left"></div>
      <div className="col s12 m4 l4 header-title">{props.Content}</div>
      <div className="col s12 m4 l4 header-rect-right"></div>
    </>
  );
}
export default Header;
