import "./css/App.css";
import "./css/Animations.css";
import Navbar from "./components/Nav/Navbar";
import Home from "./components/Home";
import { About } from "./pages/about";
import { Content } from "./pages/content";
import { Route, Routes } from "react-router-dom";
import { Contact } from "./pages/contact";
function App() {
  return (
    <>
      <body>
        <div className="container">
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/Content" element={<Content />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </div>
      </body>
    </>
  );
}

export default App;
