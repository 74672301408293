import "./../css/Content.css";
import ContentCard from "../components/cards/ContentCard";
import Header from "../components/Core/Header";
export function Content() {
  //Variables
  const headerTitle = "Content";

  const blogMessage =
    "My Blog, here you will find a reflection of previous experiences, write-ups, and other crazy Projects.";
  const blogimgPath = "/imgs/profile.png";
  const blogTitle = "Blog";
  const blogClass = "content-button-blog";
  const blogLink = "https://medium.com/@jakemellichamp";
  const mediumLogo = "/imgs/mediumLogo.png";

  const youtubePath = "/imgs/channelLogo.png";
  const youtubeTitle = "StarCoding";
  const youtubeMessage =
    "My Youtube Channel, here you will hopefully learn a quick trick or two! Join my community of future learners in Computer Science!";
  const youtubeClass = "content-button-youtube";
  const youtubeLink =
    "https://www.youtube.com/channel/UC4RGHpQWQOaPMWmRN5NQscw";
  const youtubeLogo = "/imgs/Youtube.png";
  //Render
  return (
    <>
      <div className="orb-1 hide-on-med-and-down"></div>
      <div className="orb-2 hide-on-med-and-down"></div>

      <div className="row">
        <Header Content={headerTitle} />
      </div>
      <br />
      <br />
      <div className="row">
        <div className="col s12 m1 l1"></div>
        <ContentCard
          imgPath={youtubePath}
          title={youtubeTitle}
          message={youtubeMessage}
          button={youtubeClass}
          link={youtubeLink}
          logo={youtubeLogo}
        />
        <div className="col s12 m2 l2"></div>
        <ContentCard
          imgPath={blogimgPath}
          title={blogTitle}
          message={blogMessage}
          button={blogClass}
          link={blogLink}
          logo={mediumLogo}
        />
      </div>
    </>
  );
}
