import * as React from "react";
import "./../../css/Content.css";
import "./../../css/Contact.css";
import { useState } from "react";
import M from "materialize-css";
//Create Navbar at the top of page

/*
 * Props Used in this Card:
 *  - imgPath: Path to the image needed
 *  - title: Title for the content card
 *  - message: Text below the title
 *  - button
 *  - link
 *
 */

const sendEmail = async (first, last, email, project) => {
  console.log("Sending the Payload: " + project);
  const url = `https://projectstar-backend.azurewebsites.net/api/sendgridtrigger?code=${process.env.REACT_APP_CODE}&first=${first}&last=${last}&email=${email}&message=${project}`;
  const response = await fetch(url);
  return response.status;
};

function SubmitCard(props) {
  //define states

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [project, setProject] = useState("");

  //Call this function when user clicks submit
  const onSubmit = async (event) => {
    event.preventDefault();

    if ((await sendEmail(firstName, lastName, email, project)) === 200) {
      M.toast({ html: "Message Sent Successfully", classes: "green darken-1" });
    } else {
      M.toast({
        html: "Message Failed To Deliver!",
        classes: "deep-orange darken-1",
      });
    }
  };

  return (
    <div className="col s12 m12 l12">
      <div className="content-card-container-reg">
        <div className="row">
          <h2 className="header-title">Submission Form</h2>
        </div>
        <form className="container" onSubmit={onSubmit}>
          <div className="row">
            <div className="input-field col s6">
              <input
                id={"first_name"}
                type={"text"}
                className="validate"
                onChange={(e) => setFirstName(e.target.value)}
              />
              <label htmlFor="first_name">First Name</label>
            </div>
            <div className="input-field col s6">
              <input
                id="last_name"
                type="text"
                className="validate"
                onChange={(e) => setLastName(e.target.value)}
              />
              <label htmlFor="last_name">Last Name</label>
            </div>
          </div>
          <div className="row input-field">
            <div className="input-field col s12">
              <input
                id="email"
                type="text"
                className="validate"
                onChange={(e) => setEmail(e.target.value)}
              />
              <label htmlFor="email">Email</label>
            </div>
          </div>
          <div className="row">
            <div className="input-field col s12">
              <textarea
                id="textarea1"
                className="materialize-textarea"
                onChange={(e) => setProject(e.target.value)}
              ></textarea>
              <label htmlFor="textarea1">Project Idea / Purpose </label>
            </div>
          </div>
          <div className="row">
            <input
              type="submit"
              value="Submit"
              className="btn z-depth-4  action-button center-align m12"
            />
          </div>
        </form>
      </div>
    </div>
  );
}
export default SubmitCard;
