import { Link } from "react-router-dom";

function PrimaryButton(props) {
  const transition = props.trans;
  return (
    <Link
      className={"btn z-depth-4  action-button center-align m12 " + transition}
      to="/content"
    >
      {props.text}
    </Link>
  );
}
export default PrimaryButton;
