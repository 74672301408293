import React, { Component } from "react";
import M from "materialize-css/dist/js/materialize.min.js";

class Sidebar extends Component {
  componentDidMount() {
    var elem = document.querySelector(".sidenav");
    var instance = M.Sidenav.init(elem, {
      edge: "left",
      inDuration: 250,
    });
  }

  render() {
    return (
      <div>
        <ul id="slide-out" className="sidenav amber lighten-5">
          <li />
          <li>
            <a
              href="/"
              className="waves-effect light-blue-text text-darken-4 nav-text"
            >
              <i className=" light-blue-text darken-4 material-icons">cloud</i>{" "}
              Home
            </a>
          </li>
          <li>
            <div className="divider" />
          </li>
          <li>
            <a
              href="/about"
              className="waves-effect light-blue-text text-darken-4 nav-text"
            >
              <i className=" light-blue-text darken-4 material-icons">
                account_circle
              </i>{" "}
              About
            </a>
          </li>
          <li>
            <div className="divider" />
          </li>
          <li>
            <a
              className="waves-effect light-blue-text text-darken-4 nav-text"
              href="/content"
            >
              <i className=" light-blue-text darken-4 material-icons">
                laptop_chromebook
              </i>
              Content
            </a>
          </li>
          <li>
            <div className="divider" />
          </li>
          <li>
            <a
              className="waves-effect light-blue-text text-darken-4 nav-text"
              href="/contact"
            >
              <i className=" light-blue-text darken-4 material-icons">email</i>
              Contact
            </a>
          </li>
        </ul>
        <a href="#" data-target="slide-out" className="sidenav-trigger">
          <i className=" light-blue-text darken-4 material-icons">menu</i>
        </a>
      </div>
    );
  }
}

export default Sidebar;
