import * as React from "react";
import "./../../css/Content.css";
import "./../../css/Contact.css";
//Create Navbar at the top of page

/*
 * Props Used in this Card:
 *  - title: Title for the content card
 *  - body: message under the title
 */

function Card(props) {
  return (
    <div className="content-card-container-reg">
      <div className="row">
        <h2 className="header-title">{props.title}</h2>
      </div>
      <div className="row">
        <p className="container contactText">
          Hello! I am a Software, Security, and Network Engineer who has
          spearheaded multiple software projects through out my career. To me,
          its simple, my purpose is to solve complex and meaningful problems
          through software. If you have a project idea in the following domain:
        </p>
      </div>
      <div className="row">
        <div className="container center-align">
          <div className="col s12 m6 l4">
            <i className="large light-blue-text darken-4 material-icons">
              autorenew
            </i>
            <p className="light-blue-text">
              <b>Automation</b>
            </p>
          </div>
          <div className="col s12 m6 l4">
            <i className="large light-blue-text darken-4 material-icons">tv</i>
            <p className="light-blue-text">
              <b>Website Development</b>
            </p>
          </div>
          <div className="col s12 m6 l4">
            <i className="large   light-blue-text darken-4 material-icons">
              cloud_upload
            </i>
            <p className="light-blue-text">
              <b>Cloud Migration</b>
            </p>
          </div>
          <div className="col s12 m6 l4">
            <i className="large  light-blue-text darken-4 material-icons">
              network_check
            </i>
            <p className="light-blue-text">
              <b>Network Security Auditing</b>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col s12">
            <br />
            <p className="container contactText">
              I am here to make your ideas a reality. Fill out the following web
              form! I generally reachout between 1 - 5 business days, thank you!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Card;
