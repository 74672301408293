import Header from "../components/Core/Header";
import SubmitCard from "../components/cards/SubmitCard";
import Card from "../components/cards/Card";
import "../css/Contact.css";
import "../css/Content.css";

export function Contact() {
  const headerTitle = "Contact";

  const why = "Why Contact?";
  const body =
    "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't";
  return (
    <>
      <div className="row">
        <Header Content={headerTitle} />
      </div>
      <br />
      <br />
      <br />
      <br />
      <div className="row">
        <div className="col s12 m12 l6">
          <Card title={why} body={body} />
        </div>
        <div className="col s12 m12 l6">
          <SubmitCard />
        </div>
      </div>
    </>
  );
}
