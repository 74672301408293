import React from "react";
import { Link } from "react-router-dom";
import PrimaryButton from "./Buttons/PrimaryButton";
class Home extends React.Component {
  render() {
    return (
      <div className="row">
        <div className="col s12 m12 l6">
          <div className="shineLight slide-in-left">Shining Light on Code</div>
          <div className="homeText hide-on-med-and-down focus-in-expand">
            <p>Software Engineer</p>
          </div>
          <div className="homeText hide-on-med-and-down focus-in-expand-2">
            <p>Security Engineer</p>
          </div>
          <div className="homeText hide-on-med-and-down focus-in-expand-3">
            <p>Computer Scientist</p>
          </div>

          <PrimaryButton text="Learn More" trans="focus-in-expand-4" />
        </div>
        <div className="col s12 m12 l6 mainContainer slide-in-fwd-center">
          <img
            className="starCard"
            src={process.env.PUBLIC_URL + "/imgs/StarCard.png"}
            alt="Star Card"
          ></img>

          <img
            className="starLogo"
            src={process.env.PUBLIC_URL + "/imgs/Logo.png"}
            alt="Star Card"
          ></img>
        </div>
      </div>
    );
  }
}
export default Home;
