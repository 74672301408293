import * as React from "react";
import "./../../css/Content.css";
//Create Navbar at the top of page

/*
 * Props Used in this Card:
 *  - imgPath: Path to the image needed
 *  - title: Title for the content card
 *  - message: Text below the title
 *  - button
 *  - link
 *
 */

function ContentCard(props) {
  return (
    <div className="col s12 m12 l5">
      <div className="card-content-head center">
        <img
          src={process.env.PUBLIC_URL + props.logo}
          alt="Star Card"
          className="card-logo-helper"
        />
      </div>
      <div className="content-card-container">
        <div className="content-logo center-align">
          <div className="center">
            <img src={process.env.PUBLIC_URL + props.imgPath} alt="Star Card" />
          </div>

          <h2 className="content-title"> {props.title}</h2>
          <div className="content-card-description">
            <p className="content-text ">{props.message}</p>
          </div>
          <div className="left-align">
            <a
              className={
                "waves-effect waves-light btn left-align " + props.button
              }
              href={props.link}
              target="_blank"
              rel="noreferrer"
            >
              Learn More
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ContentCard;
