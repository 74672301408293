import AboutCard from "../components/cards/AboutCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";

//Import Swiper Styles
import "swiper/css";
import "swiper/css/pagination";

import "./../css/About.css";

export function About() {
  //Project 1
  var projects = [];

  const InstaBot = {
    title: "InstaBot",
    description:
      "A instagram bot that automatically post a randomized daily quote from a series of quote worthy philosophers and leaders.",
    tags: ["Python3", "Web Scraping", "Image Processing"],
    image: "/imgs/InstaBot.png",
    link: "https://github.com/Jacob-Mellichamp/Instagram-bot",
  };
  projects.push(
    <SwiperSlide>
      <AboutCard
        title={InstaBot.title}
        description={InstaBot.description}
        tags={InstaBot.tags}
        image={InstaBot.image}
        link={InstaBot.link}
        customMessage={InstaBot.customMessage}
      />
    </SwiperSlide>
  );

  //Project 2
  const BufferOverflowAttack = {
    title: "Buffer Overflow Attack",
    description:
      'A Demonstration and explaination of how an exploitable buffer can be used to inject malicous "Shellcode" into a program.',
    tags: ["C", "Assembly", "GDB", "Reverse Engineering"],
    image: "/imgs/Stack_Overflow.png",
    link: "https://medium.com/codex/buffer-overflows-shellcode-and-memory-corruption-51f546ab00ab",
  };

  projects.push(
    <SwiperSlide>
      <AboutCard
        title={BufferOverflowAttack.title}
        description={BufferOverflowAttack.description}
        tags={BufferOverflowAttack.tags}
        image={BufferOverflowAttack.image}
        link={BufferOverflowAttack.link}
        customMessage={BufferOverflowAttack.customMessage}
      />
    </SwiperSlide>
  );

  const RiseNGrind = {
    title: "RiseNGrind",
    description:
      "RiseNGrind is a mobile application designed to reimagine the way people wake up, by taking away the “abruptness” of waking up, and attempting to harness the ability to make it natural again.",
    tags: ["Java", "Android", "Mobile App", "UI/UX"],
    image: "/imgs/RiseNGrind.png",
    link: "https://github.com/Jacob-Mellichamp/RiseNGrind",
  };

  projects.push(
    <SwiperSlide>
      <AboutCard
        title={RiseNGrind.title}
        description={RiseNGrind.description}
        tags={RiseNGrind.tags}
        image={RiseNGrind.image}
        link={RiseNGrind.link}
      />
    </SwiperSlide>
  );

  const PoseEstimation = {
    title: "Pose Estimation",
    description:
      "Using Python’s Tensor flow library for face detection and OpenCV for facial rotation an application was developed to test whether someone is engaging with their workstation.",
    tags: ["OpenCV", "TensorFlow", "Python3", "PnP"],
    image: "/imgs/PoseEstimation.png",
    link: "https://medium.com/codex/distraction-detection-using-pose-estimation-with-opencv-and-tensorflow-16f28e733da4",
  };
  projects.push(
    <SwiperSlide>
      <AboutCard
        title={PoseEstimation.title}
        description={PoseEstimation.description}
        tags={PoseEstimation.tags}
        image={PoseEstimation.image}
        link={PoseEstimation.link}
      />
    </SwiperSlide>
  );

  const Medusa = {
    title: "Medusa",
    description:
      'Medusa is a Counter UAS Detection and Mitigation system made to "turn drones into stone." This device Detects, Identifies, and mitigates drones in local proximity',
    tags: ["C", "Bash", "IoT", "WireShark", "ML"],
    image: "/imgs/CDAMs.png",
    link: "#",
    customMessage: "Writeup Pending",
  };

  projects.push(
    <SwiperSlide>
      <AboutCard
        title={Medusa.title}
        description={Medusa.description}
        tags={Medusa.tags}
        image={Medusa.image}
        link={Medusa.link}
        customMessage={Medusa.customMessage}
      />
    </SwiperSlide>
  );

  return (
    <>
      <div className="orb-1 hide-on-med-and-down"></div>
      <div className="orb-2 hide-on-med-and-down"></div>

      <div className="row">
        <div className="col s12 m4 l4 header-rect-left"></div>

        <div className="col s12 m4 l4 header-title">About / Services</div>

        <div className="col s12 m4 l4 header-rect-right"></div>
      </div>

      <div className="row">
        <Swiper
          slidesPerView={3}
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Pagination, Navigation]}
          className="myLargeSwiper"
        >
          {projects}
        </Swiper>

        <div className="mySmallSwiper">{projects}</div>
      </div>
    </>
  );
}
